.button {
  @extend .font-os;
  @extend .font-bold;

  border: none;
  cursor: pointer;
  display: block;
  font-size: 16px;
  opacity: 0.8;
  padding: 12px 24px;
  transition: opacity 150ms ease-in-out;
  width: 100%;

  @include breakpoint-lg {
    max-width: 50%;
  }

  &:hover {
    opacity: 1;
  }
}

.button-primary {
  background-color: var(--color-primary);
}
