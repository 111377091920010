@import '../../../styles/breakpoints.scss';

.carousel-wrapper.vertical {
  position: relative;

  //   This defines the position of the bottom button
  padding-bottom: 60px;

  --padding-sides: 40px;
  padding-left: var(--padding-sides);
  padding-right: var(--padding-sides);

  .carousel-container {
    position: relative;
    overflow: hidden;
    height: 100%;

    .carousel-slide {
      position: absolute;
      transition: transform 250ms ease;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .carousel-nav-button {
    cursor: pointer;
    position: absolute;
    z-index: 10;

    height: 50px;
    width: 50px;

    left: calc(50% - 25px);

    background: transparent;
    border: none;

    transition: all 150ms ease;

    &:hover {
      height: 60px;
      width: 60px;

      left: calc(50% - 30px);
    }

    &.button-previous {
      top: -60px;
      transform: rotate(180deg);

      &:hover {
        top: -65px;
      }
    }

    &.button-next {
      bottom: 0;

      &:hover {
        bottom: -5px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    @include breakpoint-md {
      display: block;
    }
  }
}
