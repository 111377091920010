$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

@mixin breakpoint-sm {
  @media screen and (max-width: $breakpoint-md) {
    @content;
  }
}
@mixin breakpoint-md {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin breakpoint-2xl {
  @media screen and (min-width: $breakpoint-2xl) {
    @content;
  }
}
