@import './breakpoints.scss';

.banner-image {
  @include breakpoint-md {
    max-height: 384px;
  }

  @include breakpoint-lg {
    max-height: 480px;
  }

  @include breakpoint-xl {
    max-height: 550px;
  }

  @include breakpoint-2xl {
    max-height: 550px;
  }

  @media (min-width: 1920px) {
    max-height: 700px;
  }
}
