@import '../../../styles/breakpoints.scss';

.carousel-wrapper.horizontal {
  position: relative;

  .carousel-container {
    position: relative;
    overflow: hidden;

    .carousel-slide {
      position: absolute;

      transition: transform 250ms ease;

      img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .carousel-crumbs {
    height: 25px;

    padding: 10px 0;

    display: flex;

    justify-content: center;
    align-items: center;

    @include breakpoint-md {
      display: none;
    }

    .crumb {
      cursor: pointer;

      box-sizing: border-box;

      background: rgba(0, 0, 0, 0.25);

      height: 15px;

      flex: 0 0 15px;

      margin: 0 10px;

      border-radius: 100%;

      transition: all 150ms ease;

      &:hover {
        background: rgba(0, 0, 0, 0.75);
      }

      &.is-active {
        background: rgba(0, 0, 0, 0.75);
        transform: scale(1.5);
      }

      @include breakpoint-md {
        display: none;
      }
    }
  }
}
