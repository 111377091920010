@import './styles/main.scss';

::selection {
  background-color: var(--color-primary);
}

body {
  @extend .font-os;
}

img {
  display: block;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
}

p {
  font-size: 14px;
}

strong {
  font-weight: bold;
}
