@import '../../../styles/breakpoints.scss';

//   Logo Image
#nav-main--logo-img {
  --height-sm: 48px;
  --height-lg: 64px;

  height: var(--height-sm);

  @include breakpoint-lg {
    height: var(--height-lg);
  }
}

@media screen and (max-width: 1023px) {
  nav#nav-main {
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    //   Menu
    #nav-main--menu {
      position: absolute;
      right: 0;
      top: 48px;
      width: 100%;

      list-style: none;
      margin: 0;
      padding: 0;

      overflow-x: hidden;
      text-align: right;

      z-index: 50;

      & > li {
        cursor: pointer;
        transform: translateX(100%);

        &.out {
          animation: slide-out 150ms;
          animation-fill-mode: forwards;
        }

        &.in {
          animation: slide-in 150ms;
          animation-fill-mode: forwards;

          @for $i from 1 through 10 {
            &:nth-child(#{$i}n) {
              animation-delay: #{$i * 75}ms;
            }
          }

          a.active span {
            color: var(--color-primary);

            &::after {
              width: 100%;
            }
          }
        }

        a,
        div,
        ul {
          cursor: pointer;
          color: #fff;
          display: block;
          font-weight: bold;
          padding: 12px 24px;
          text-decoration: none;
          text-transform: uppercase;

          span {
            position: relative;
            transition: color 150ms;
            transition-delay: 500ms;

            &:after {
              content: '';
              height: 2px;
              width: 0;
              background: var(--color-primary);
              position: absolute;
              bottom: -4px;
              left: 0;
              transition: width 150ms;
              transition-delay: 500ms;
            }
          }

          .nav-main--menu--caret {
            margin-right: 12px;
            height: 100%;
          }
        }

        div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .menu--dropdown-header {
          & > * {
            pointer-events: none;
          }

          span.has-active {
            color: var(--color-primary);
          }
        }

        div.menu--dropdown-wrapper {
          padding: 0;
          position: relative;
          height: 0;
          overflow: hidden;
          transition: height 250ms ease;

          ul.menu--dropdown-inner {
            overflow: hidden;
            padding: 0;

            li {
              a {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 48px;

                span {
                  margin: 0 12px;
                }
              }
            }
          }
        }
      }
    }

    #nav-main--button-toggle {
      background-color: transparent;
      border: none;
      height: 48px;
      margin: 0;
      padding: 0;
      position: relative;
      width: 48px;

      span {
        align-items: center;
        background-color: transparent;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 2rem;
        height: 48px;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 48px;
        opacity: 0;
        transition: opacity 250ms;

        &.shown {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
