@import './spacing.scss';

.container {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @include breakpoint-sm {
    max-width: $breakpoint-md;
    padding-left: $padding-sm-horizontal;
    padding-right: $padding-sm-horizontal;
  }

  @include breakpoint-md {
    max-width: $breakpoint-lg;
    padding-left: $padding-md-horizontal;
    padding-right: $padding-md-horizontal;
  }

  @include breakpoint-lg {
    max-width: $breakpoint-xl;
    padding-left: $padding-lg-horizontal;
    padding-right: $padding-lg-horizontal;
  }

  @include breakpoint-xl {
    max-width: $breakpoint-2xl;
    padding-left: $padding-xl-horizontal;
    padding-right: $padding-xl-horizontal;
  }

  @include breakpoint-2xl {
  }
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.bg-test {
  background-color: coral;
}

.relative {
  position: relative;
}

.text-right {
  text-align: right;
}
