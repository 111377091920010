$padding-sm-vertical: 12px;
$padding-sm-horizontal: 24px;

$padding-md-vertical: 16px;
$padding-md-horizontal: 48px;

$padding-lg-vertical: 16px;
$padding-lg-horizontal: 64px;

$padding-xl-vertical: 24px;
$padding-xl-horizontal: 80px;

$margin-sm-vertical: 12px;
$margin-sm-horizontal: 24px;

$margin-md-vertical: 24px;
$margin-md-horizontal: 48px;

$margin-lg-vertical: 48px;
$margin-lg-horizontal: 64px;

$margin-xl-vertical: 64px;

$breakpoints: $breakpoint-md, $breakpoint-lg, $breakpoint-xl, $breakpoint-2xl;
$bp-prefixes: md, lg, xl, xl2;

$sizes: 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5,
  4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.25, 9.5,
  9.75, 10, 10.25, 10.5, 10.75, 11, 11.25, 11.5, 11.75, 12, 12.25, 12.5, 12.75, 13, 13.25, 13.5,
  13.75, 14, 14.25, 14.5, 14.75, 15, 15.25, 15.5, 15.75, 16, 16.25, 16.5, 16.75, 17, 17.25, 17.5,
  17.75, 18, 18.25, 18.5, 18.75, 19, 19.25, 19.5, 19.75, 20;

@mixin generate($prefix, $property) {
  $multiplier: 1rem;

  @for $size-i from 1 through length($sizes) {
    $size: nth($sizes, $size-i);

    .#{$prefix}-#{$size-i - 1} {
      #{$property}: $size * $multiplier;
    }
  }

  @for $breakpoint-i from 1 through length($breakpoints) {
    $breakpoint: nth($breakpoints, $breakpoint-i);
    $bp-prefix: nth($bp-prefixes, $breakpoint-i);

    @media screen and (min-width: $breakpoint) {
      @for $size-i from 1 through length($sizes) {
        $size: nth($sizes, $size-i);

        .#{$bp-prefix}-#{$prefix}-#{$size-i - 1} {
          #{$property}: $size * $multiplier;
        }
      }
    }
  }
}

@include generate('mt', margin-top);
@include generate('mb', margin-bottom);

@include generate('pt', padding-top);
@include generate('pb', padding-bottom);

@include generate('pr', padding-right);
@include generate('pl', padding-left);
