@import '../../../styles/breakpoints.scss';

@include breakpoint-lg {
  nav#nav-main {
    --menu-height: 64px;
    --menu-link-color: #fff;

    display: flex;
    background-color: #000;

    #nav-main--logo-img {
      height: var(--menu-height);
    }

    #nav-main--menu {
      height: var(--menu-height);
      display: flex;
      width: 100%;
      margin: 0;
      list-style-type: none;
      align-items: stretch;
      justify-content: flex-end;
      gap: 2rem;

      li {
        a,
        div.menu--dropdown-header {
          user-select: none;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: var(--menu-link-color);
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            transition: color 250ms ease;

            &:hover {
              color: var(--color-primary);
            }
          }
        }

        a.active {
          span {
            color: var(--color-primary);
          }
        }
      }

      li.menu--dropdown {
        position: relative;

        div.menu--dropdown-header {
          color: var(--menu-link-color);

          &:hover {
            color: var(--color-primary);
          }

          .nav-main--menu--caret {
            margin-right: 12px;
          }

          & > * {
            pointer-events: none;
          }

          span.has-active {
            color: var(--color-primary);
          }
        }

        &:hover .menu--dropdown-wrapper {
          max-height: 200px;
          height: auto;
        }

        div.menu--dropdown-wrapper {
          max-height: 0;

          background-color: #000;
          position: relative;
          bottom: 0;
          overflow: hidden;
          transition: all 250ms ease;

          ul.menu--dropdown-inner {
            padding: 0;

            li {
              a {
                background-color: #000;
                padding: 12px;

                svg {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    #nav-main--button-toggle {
      display: none;
    }
  }
}
