:root {
  --color-primary: #d8df25;
  --color-primary-dark: darken(var(--color-primary), 50%);
  --color-secondary: #181d00;
  --color-accent: #8b057a;
  --color-gray: #e8e8e8;
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-accent {
  color: var(--color-accent);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.bg-gray {
  background-color: var(--color-gray);
}

.bg-white {
  background-color: #fff;
}

.bg-accent {
  background-color: var(--color-accent);
}
