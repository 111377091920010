.form-control {
  --error-color: rgb(200, 0, 0);
  --input-margin: 8px;
  --input-padding: 12px;
  --font-size: 16px;
  --background-color: #e4e4e4;

  margin-top: 24px;

  &.has-error {
    input {
      border-style: solid;
    }
  }

  label {
    display: block;
    font-size: var(--font-size);
    font-weight: bold;
    text-transform: uppercase;
  }

  input {
    box-sizing: border-box;
    height: 40px;
    width: 100%;

    border-style: none;
    border-width: 1px;
    border-color: var(--error-color);
    border-radius: 0;

    background-color: var(--background-color);

    margin: var(--input-margin) 0;
    padding: 0 var(--input-padding);

    font-size: var(--font-size);
    font-weight: bold;
  }

  textarea {
    box-sizing: border-box;

    background-color: var(--background-color);

    border: none;
    border-radius: 0;

    resize: none;

    width: 100%;
    height: 150px;

    font-size: var(--font-size);
    font-family: 'Open-Sans', sans-serif;

    padding: var(--input-padding);
    margin: var(--input-margin) 0;
  }

  .form-error {
    color: var(--error-color);
    text-align: right;
  }
}
