@import '../../../styles/breakpoints.scss';

.homepage--mission-flex-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;

  overflow: hidden;

  display: flex;

  .homepage--mission-text-wrapper {
    flex: 0 0 60%;

    p + p {
      margin-top: 16px;
    }
  }

  .homepage--mission-image-wrapper {
    position: relative;
    flex: 1 0 auto;

    img {
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: auto;
    }
  }

  @media screen and (min-width: 550px) {
    overflow: visible;

    .homepage--mission-text-wrapper {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 14px;
      }
    }

    .homepage--mission-image-wrapper {
      img {
        height: auto;
        width: 100%;

        top: auto;
        bottom: 0;
      }
    }
  }

  @include breakpoint-lg {
    min-height: 30vh;

    .homepage--mission-text-wrapper .p-box {
      p {
        font-size: 18px;
      }
    }

    .homepage--mission-image-wrapper {
      bottom: -20px;
    }
  }

  @include breakpoint-xl {
    min-height: 35vh;

    .homepage--mission-text-wrapper .p-box {
      p {
        font-size: 20px;
      }
    }

    .homepage--mission-image-wrapper {
      img {
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 560px;
      }
    }
  }

  @include breakpoint-2xl {
    .homepage--mission-image-wrapper {
      img {
        max-width: 600px;
      }
    }
  }
}
