@import '../../../styles/breakpoints.scss';
@import '../../../styles/spacing.scss';

.bestseller--wrapper {
  --bar-height: 115px;
  position: relative;

  h1 {
    box-sizing: border-box;
    background-color: #000;
    color: var(--color-primary);
    text-transform: uppercase;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;

    @include breakpoint-md {
      width: 35%;
      text-align: left;
      position: absolute;
      top: calc(var(--bar-height) / 3 * -1);
      font-size: 32px;
      right: $padding-md-horizontal;
      padding-top: 6px;
      padding-bottom: 6px;

      &:after {
        content: '';
        height: 100%;
        background: #000;
        position: absolute;
        width: 90%;
        left: -90%;
        top: 0;
      }
    }

    @include breakpoint-xl {
      width: 38%;
      font-size: 56px;
    }
  }

  .bestseller--bottom-wrapper {
    @include breakpoint-md {
      display: flex;
    }

    .bestseller--image-wrapper {
      width: 100%;
      flex: 0 0 50%;
      z-index: 50;
      position: relative;

      @include breakpoint-md {
        &:after {
          content: '';
          height: var(--bar-height);
          background: var(--color-primary);
          position: absolute;
          width: 100%;
          left: -50%;
          bottom: calc(var(--bar-height) / 3 * -1);
          z-index: -1;
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .bestseller--description {
      padding-top: 24px;
      padding-bottom: 24px;

      h2 {
        font-weight: bold;
        margin-bottom: 12px;
      }

      @include breakpoint-md {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include breakpoint-xl {
        h2 {
          font-size: 40px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }

  @include breakpoint-md {
    max-width: $breakpoint-lg;
    padding-left: $padding-md-horizontal;
    padding-right: $padding-md-horizontal;
  }

  @include breakpoint-lg {
    max-width: $breakpoint-xl;
    padding-left: $padding-lg-horizontal;
    padding-right: $padding-lg-horizontal;
  }

  @include breakpoint-xl {
    max-width: $breakpoint-2xl;
    padding-left: $padding-xl-horizontal;
    padding-right: $padding-xl-horizontal;
  }
}
