@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.font-os {
  font-family: 'Open Sans', sans-serif;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}
