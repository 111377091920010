@import '../../styles/breakpoints.scss';

.homepage--tiles {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @include breakpoint-md {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    h1 {
      font-size: 20px;
    }
  }
}
