.form-input {
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  cursor: text;

  width: 100%;

  @include breakpoint-lg {
    max-width: 50%;
  }

  & + .form-input {
    margin-top: 12px;
  }

  & > .icon {
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 0 12px;
    opacity: 0.5;
  }

  & > input {
    width: 100%;
    border: none;
    background-color: transparent;
    padding-top: 12px;
    padding-bottom: 12px;

    &:focus {
      outline: none;
    }
  }
}
